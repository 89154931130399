<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增账号"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="登录账号">
          <a-input
            v-decorator="['username', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入登录账号' },
                { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '只能由4-50位 字母、数字 或 下划线 组成' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="姓名">
          <a-input
            v-decorator="['full_name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入姓名' },
                { max: 50, message: '最多50个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="登录密码">
          <a-input-password
            autocomplete="new-password"
            v-decorator="['password', {
              rules: [
                { required: true, message: '请输入登录密码' },
                { min: 6, message: '最少6个字符' },
                { max: 50, message: '最多50个字符' },
                { pattern: /^\S.*\S$/, message: '首尾不能为空格' }
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="手机号">
          <a-input
            v-decorator="['phone_number', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入手机号' },
                { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="联系邮箱">
          <a-input
            v-decorator="['email', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入联系邮箱' },
                { pattern: /^[a-z0-9._%-]+@([a-z0-9-]+\.)+[a-z]{2,4}$/, message: '邮箱格式不正确' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="角色">
          <a-select
            v-decorator="['role_id', {
              rules: [{ required: true, message: '请选择角色' }]
            }]"
            @change="handleRoleChange"
            :loading="roleOptions.length === 0"
          >
            <a-select-option
              v-for="role in roleOptions"
              :key="role.id"
              :value="role.id"
              :slug="role.slug"
              :disabled="!role.effective"
            >
              {{ role.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="销售助理" v-show="isShowSalesAssistant">
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            :loading="loadingSalesAssistantOption"
            v-decorator="['assistant_user_id', {
              rules: [{ required: isShowSalesAssistant, message: '请选择销售助理' }]
            }]"
          >
            <a-select-option
              v-for="user in salesAssistantOptions"
              :key="user.id"
              :value="user.id"
            >
              {{ user.full_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createUser, findEffectiveSalesAssistantOptions } from '@/api/user'
import { findRoleOptions } from '@/api/role'

export default {
  name: 'NewUser',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'user' }),
      submitting: false,
      roleOptions: [],
      salesAssistantOptions: [],
      loadingSalesAssistantOption: false,
      isShowSalesAssistant: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchRoleOptions()
    this.fetchSalesAssistantOptions()
  },
  methods: {
    // 加载角色选项
    fetchRoleOptions() {
      findRoleOptions().then((res) => {
        // 不能新增超级管理员
        this.roleOptions = this.$lodash.reject(res.data, { slug: 'super_admin' })
        // 管理员不能新增管理员角色
        if (this.$store.getters.userRole === 'admin') {
          this.roleOptions = this.$lodash.reject(this.roleOptions, { slug: 'admin' })
        }
      })
    },

    fetchSalesAssistantOptions() {
      this.loadingSalesAssistantOption = true
      findEffectiveSalesAssistantOptions().then((res) => {
        if (res.code === 0) {
          this.salesAssistantOptions = res.data
          this.loadingSalesAssistantOption = false
        }
      })
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleRoleChange(e, o) {
      if (o.data.attrs.slug === 'sale') {
        this.isShowSalesAssistant = true
      } else {
        this.isShowSalesAssistant = false
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          createUser(values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
